/* Reset and global styles */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto; /* Prevent unwanted scrolling */
  font-family: 'Roboto', sans-serif;
}

/* Utility classes (optional) */
.full-height {
  height: 100%;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
